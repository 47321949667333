#profile-box {
    font-family: 'Roboto', sans-serif;
    text-align: center;

    margin: auto;

    color: white;

    min-height: 97vh;
    max-height: 97vh;
    
    box-shadow: 0px 8px 20px rgb(0, 160, 30);

    border-radius: 25px;
    border: 2px solid rgb(10, 255, 100);

    overflow: hidden;
}

#user-trophies-container {
    display: flex;
    justify-content: space-between;
    
    position: absolute;
    top: 2vh;

    min-width: 38vw;
    max-width: 38vw;
    height: 28vh;

    overflow-x: auto;
    overflow-y: hidden;

    padding: 1vw;
    margin-left: 2vw;

    border: 2px solid rgb(225, 225, 40);
    border-radius: 12px;
}

.user-profile-boxes {
    display: flex; 
    justify-content: center;

    border: 2px solid rgb(225, 225, 40);
    border-radius: 12px;

    margin-top: 30.5vh;
    margin-left: 2vw;

    overflow-Y: auto;
    overflow-x: hidden;

    padding: 1vh;

    flex-wrap: wrap;

    min-width: 18vw;
    max-width: 18vw;

    max-height: 57vh;

    z-Index: 300;
}

.user-stat-boxes {
    font-size: 18px;
    
    background-color: rgb(255, 140, 0);

    border: none;
    border-bottom: 4px solid rgb(205, 90, 0);
    
    text-align: center;

    min-width: 13vw;
    max-width: 13vw;

    height: 8vh;

    margin: 2vh;

    padding: 1vw;

    border-radius: 12px;

    cursor: pointer;
}

.edit-account-btns {
    font-family: 'Roboto', sans-serif;
    line-height: 3.5vh;

    background-color: rgb(140, 0, 55);
    font-size: 16px;

    margin-top: 2vh;

    color: white;

    padding: 0.3vw;

    min-width: 14vw;
    max-width: 14vw;

    height: 4vh;

    border: 4px solid transparent;
    border-bottom: 3px solid rgb(105, 0, 40);

    border-radius: 6px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    cursor: pointer;
}

#stat-swivel {
    animation: stat-swivel .2s;
}

#stat-re-swivel {
    animation: stat-re-swivel .2s;
}

@keyframes stat-swivel {
    from {height: 0vh;}
}

@keyframes stat-re-swivel {
    from {height: 0vh;}
}