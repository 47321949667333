.columns {
    display: inline-flex;
    flex-direction: column;

    justify-content: center;

    position: relative;
    bottom: 0vh;

    animation: col-drop 0.6s;
}

@keyframes col-drop {
    from {bottom: 100vh}
    to {bottom: 0vh}
}