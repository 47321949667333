.letters {
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: 'Roboto', sans-serif;
    font-size: 1.6em;

    width: 3.6vw;
    height: 7.6vh;

    margin: 2px;

    position: relative;
    bottom: 22vh;

    overflow: hidden;

    z-index: 100;
}

.new-letters {
    position: relative;

    animation: drop 0.4s forwards;
}

.rearranged-letters {
    position: relative;
    
    animation: redrop 0.4s forwards;
}

@keyframes drop {
    from {bottom: 100vh}
    to {bottom: 22vh}
}

@keyframes redrop {
    from {bottom: 30vh}
    to {bottom: 22vh}
}