#game-box {
    display: grid;
    justify-items: center;
    align-items: center;

    min-height: 91vh;
    max-height: 91vh;
    min-width: 62vw;
    max-width: 62vw;

    position: relative;

    margin: auto;

    border-radius: 25px;
    border: 2px solid rgb(10, 255, 100);

    overflow: hidden;
}