.player-headings {
    display: flex;
    justify-content: center;
    font-family: 'Bungee Spice';

    width: 28vw;

    padding: 0.5vw;
    margin-bottom: 2vh;

    border-top: 2px solid rgb(120, 120, 255);
    border-bottom: 2px solid rgb(120, 120, 255);
}

.challenge-selections {
    font-family: 'Roboto';
    line-height: 6vh;

    width: 12vw;
    height: 6vh;

    margin: 2vh;

    background-color: rgb(140, 0, 55);
    border-bottom: 3px solid rgb(105, 0, 40);
    border-radius: 8px;

    cursor: pointer;
}