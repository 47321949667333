.navigation-buttons {
    font-family: 'Roboto', sans-serif;

    background-color: rgb(140, 0, 55);
    font-size: 18px;

    height: 3.5vh;

    padding: 0.5vw;

    border: none;
    border-bottom: 3.5px solid rgb(105, 0, 40);

    line-height: 3.8vh;

    border-radius: 0px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    cursor: pointer;
}