#points {
    display: flex;
    justify-content: center;

    font-family: 'Roboto', sans-serif;

    position: relative;
    bottom: 42vh;

    animation: slide;
    animation-duration: 0.2s;
}

#ingame-badge {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;

    text-align: center;

    min-width: 18vw;

    margin-top: -6vh;
    margin-right: 0.5vw;

    color: white;

    position: relative;
    bottom: 34vh;

    animation: badge-slide;
    animation-duration: 0.2s;
}

@keyframes slide {
    from {bottom: 32vh}
    to {bottom: 42vh}
}

@keyframes badge-slide {
    from {bottom: 20vh}
    to {bottom: 34vh}
}