.cleared-letters {
    min-width: 3.58vw;
    max-width: 3.58vw;
    min-height: 7.58vh;
    max-height: 7.58vh;

    margin: 2px;

    border-radius: 100vh;

    position: absolute;
    bottom: 22vh;

    overflow: hidden;
    z-index: 0;

    opacity: 0;

    animation: fade-in .6s, fade-out .8s;
}

@keyframes fade-in {
    from {opacity: 0}
    to {opacity: 1}
}

@keyframes fade-out {
    from {opacity: 1}
    to {opacity: 0}
}