#game-over {
    min-height: 89vh;
    max-height: 89vh;
    min-width: 44vw;
    max-width: 44vw;
    
    box-shadow: 0px 10px 20px rgb(0, 160, 30);

    position: relative;
    top: 3vh;
    margin: auto;

    border-radius: 25px;
    border: 2px solid rgb(10, 255, 100);

    opacity: 1;

    animation: game-over-fade-in 2s;
}

#gameover-header {
    text-align: center;
    text-shadow: black 0px 8px 10px;

    font-family: 'Bungee Spice', cursive;

    position: relative;
    bottom: 2vh;

    font-size: 9.5vh;
}

#stats-box {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    align-items: center;
    text-align: center;
    font-size: 18px;

    font-family: 'Roboto', sans-serif;
    color: white;

    background-color: rgb(10, 10, 10);

    min-width: 20vw;
    max-width: 20vw;
    
    box-shadow: 0px 0px 8px 1.5px blue;

    margin: auto;
    margin-top: -12vh;

    overflow-y: auto;
    overflow-x: hidden;

    border: 2px solid rgb(120, 120, 255);
    border-radius: 20px;
}

b {
    color: rgb(95, 255, 0);
}

#play-again {
    display: flex;
    justify-content: center;

    font-family: 'Bungee Spice', cursive;
    text-shadow: black 0px 2px 4px;
    font-size: 28px;
    line-height: 7vh;

    width: 18vw;
    height: 8vh;

    background-color: rgb(140, 0, 55);

    margin: auto;
    margin-top: 2vh;

    border: none;
    border-radius: 10px;
    border-bottom: 5px solid rgb(105, 0, 40);

    cursor: pointer;
}

#play-again:hover {
    box-shadow: 0px 0px 10px 3px rgb(115, 0, 45);
}

.badges-li {
    background-color: rgb(20, 20, 20);

    text-align: center;
    line-height: 5px;

    min-width: 13vw;
    max-width: 13vw;

    padding: 4px;

    margin-bottom: 2vh;

    border: 2px solid #FFD700; 
    border-radius: 12px;

    cursor: pointer;
}

#new-trophy-popup {
    animation: trophy-drop 0.3s;
}

@keyframes trophy-drop {
    from {margin-top: -23vh}
    to {margin-top: -3vh}
}

@keyframes game-over-fade-in {
    from {opacity: 0}
    to {opacity: 1}
}

::-webkit-scrollbar {
    height: 3px;
    width: 3px;
	background-color: transparent;
}

::-webkit-scrollbar-track {
	border-radius: 44px;

    margin-top: 3vh;
    margin-bottom: 3vh;
    margin-left: 3vh;
    margin-right: 3vh;
    
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 44px;
	background-color: rgb(10, 255, 100);
}