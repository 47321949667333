#menu-box {
    text-align: center;

    flex-wrap: wrap;
    box-sizing: content-box;

    max-width: 1px;
    margin: auto;

    color: white;

    min-height: 97vh;
    max-height: 97vh;
    min-width: 55vw;
    max-width: 55vw;
    
    box-shadow: 0px 8px 20px rgb(0, 160, 30);

    border-radius: 25px;
    border: 2px solid rgb(10, 255, 100);

    overflow: hidden;
}

.menu-buttons {
    border: none;

    font-family: 'Roboto';
    font-weight: bold;
    font-size: 24px;

    color: white;

    background-color: transparent;
    cursor: pointer;
}

.menu-buttons:hover {
    font-style: italic;
    text-shadow: 0px 1px 2px rgb(40, 40, 40);
}

.menu-button-divs {
    justify-content: center;

    top: 20vh;

    height: 10vh;
    width: 20vw;

    border: none;
    border-radius: 6px;

    margin: auto;
    margin-bottom: 5vh;

    cursor: pointer;
}

.menu-back-buttons {
    display: flex;
    justify-content: center;
    margin: auto;

    font-family: 'Roboto';
    font-weight: bold;
    font-size: 24px;

    color: rgb(95, 255, 0);
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.menu-back-buttons:hover {
    font-style: italic;
    text-shadow: 0px 1px 2px rgb(40, 40, 40);
}

.instructions-containers {
    display: flex;
    justify-content: center;

    margin: auto;

    margin-bottom: 4vh;
    
    min-width: 26vw;
    max-width: 26vw;

    border: none;

    border-radius: 8px;

    cursor: pointer;
}

.theme-containers {
    font-family: 'Roboto', sans-serif;
    font-size: 24px;

    line-height: 8vh;

    color: white;

    list-style: none;

    padding: 0.6vw;
    margin-bottom: 5vh;

    min-width: 16vw;
    max-width: 16vw;
    min-height: 8vh;
    max-height: 8vh;

    border-radius: 12px;

    cursor: pointer;
}

.difficulty-containers {
    display: flex;
    justify-content: center;

    line-height: 6vh;

    padding: 1vw;
    margin-bottom: 4vh;

    min-width: 20vw;
    max-width: 20vw;

    border: none;

    border-radius: 6px;

    cursor: pointer;
}

.difficulty-desc {
    font-family: 'Roboto';
    margin-top: -0.5vh;
    font-style: italic;
    line-height: 2vh;
}

.play-difficulty {
    font-size: 26px;
    margin-top: 5vh;
}

.play-difficulty:hover {
    font-style: italic;
    text-shadow: 0px 1px 2px rgb(40, 40, 40);
}

.signup-form-inputs {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    text-align: center;

    color: white;

    background-color: rgb(60, 60, 60);

    height: 6vh;
    width: 16vw;

    border: none;
    border-radius: 4px;

    cursor: pointer;
}

.signup-button {
    display: flex;
    justify-content: center;

    font-family: 'Roboto', sans-serif;
    line-height: 6vh;

    background-color: rgb(140, 0, 55);
    font-size: 16px;

    margin: auto;

    color: white;

    min-width: 12vw;
    max-width: 12vw;

    min-height: 6vh;
    max-height: 6vh;

    border: none;
    border-bottom: 3.5px solid rgb(105, 0, 40);

    border-radius: 6px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    cursor: pointer;
}

#phone-display {
    display: flex;
    justify-content: center;
    
    text-align: center;
    
    font-family: 'Bungee Spice', cursive;
    font-size: 18px;

    background-color: black;

    flex-wrap: wrap;
    box-sizing: content-box;

    margin: auto;

    min-height: 85vh;
    max-height: 85vh;
    min-width: 94vw;
    max-width: 94vw;
    
    box-shadow: 0px 8px 20px rgb(0, 160, 30);

    border-radius: 25px;
    border: 2px solid rgb(10, 255, 100);

    overflow: hidden;
}