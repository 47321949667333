#score-tab {
    position: absolute;

    margin-top: 1.2vh;
    margin-right: 41vw;

    font-family: 'Roboto'; 
    font-size: 2.4vh;
    text-align: center;
    text-shadow: none;

    height: 6vh;
    min-width: 8vw;
    max-width: 10vw;

    color: white; 
    background-color: rgb(140, 0, 55);

    border: 3px solid rgb(105, 0, 40);
    border-radius: 12px;

    padding: 4px;

    line-height: 3.5vh;

    cursor: default;
}

.input-actions {
    display: flex;
    justify-content: center;

    margin: auto;
    
    position: relative;
    bottom: 21vh;

    animation: input-drop 0.6s;
}

#word-bar {
    color: white;

    font-family: 'Roboto', sans-serif;
    font-size: 1.6vw;
    letter-spacing: 1px;

    text-align: center;

    background-color: rgb(20, 20, 20);

    margin-top: 0.2vh;

    border: 2px solid rgb(120, 120, 255);
    border-bottom: 3px solid rgb(90, 90, 210);

    box-shadow: 0px 0px 10px 1px rgb(90, 90, 210);
    border-radius: 8px;
    
    min-width: 51vw;
    max-width: 51vw;

    height: 6.2vh;

    pointer-events: none;
}

#clear {
    display: flex;
    justify-content: center;

    margin-top: 0.1vh;
    margin-right: 0.2vw;

    background-color: #FF4742;
    border-bottom: 3.5px solid rgb(170, 0, 35);
    border-radius: 8px;

    min-width: 2.4vw;
    max-width: 2.4vw;

    min-height: 7.2vh;
    max-height: 7.2vh;

    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    box-sizing: border-box;

    touch-action: manipulation;

    cursor: pointer;
}

#send {
    display: flex;
    justify-content: center;

    margin-top: 0.1vh;
    margin-left: 0.2vw;

    min-width: 2.4vw;
    max-width: 2.4vw;

    min-height: 7.2vh;
    max-height: 7.2vh;

    background-color: #13aa52;

    border-bottom: 3.5px solid rgb(0, 100, 20);
    border-radius: 8px;

    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    box-sizing: border-box;

    touch-action: manipulation;

    cursor: pointer;
}

#offerlife-container {
    border: 8px solid transparent;
    bottom: 30vh;
    font-family: 'Roboto';
    position: absolute;
    color: white;
    border-radius: 100vh;
    box-shadow: 0px 0px 60px 8px rgb(90, 90, 210);
    width: 20vw;
    height: 40vh;
    z-index: 300;

    animation: offerlife-slide .4s, reduce-border 6s;
}

#lightning-flash-div {
    animation: lightning-fade 0.5s;
}

#hint-icon {
    opacity: 0.2;
    animation-name: robo-fade;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

#hint-status-bar {
    animation: hint-glow 2s infinite;
}

@keyframes input-drop {
    from {bottom: 100vh}
    to {bottom: 21vh}
}

@keyframes offerlife-slide {
    from {bottom: 60vh}
    to {bottom: 30vh}
}

@keyframes reduce-border {
    from {border-color: rgb(120, 120, 255)}
    to {border-color: transparent}
}

@keyframes challenge-timer {
    from {width: 50vw}
    to {width: 0vw}
}

@keyframes lightning-fade {
    from {height: 0%; opacity: 1}
    to {height: 115%; opacity: 0;}
}

@keyframes robo-fade {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
}

@keyframes hint-glow {
    0% {
      box-shadow: 0 0 10px 5px rgb(100, 125, 255);
    }
    50% {
      box-shadow: 0 0 20px 10px rgb(100, 125, 255);
    }
    100% {
      box-shadow: 0 0 10px 5px rgb(100, 125, 255);
    }
}